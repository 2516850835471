import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 70, 204',
		'primary-dark': '0, 45, 133',
		'accent': '94, 195, 98',
		'accent-plus': '255, 255, 255',
	},
});
